@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700&display=swap");

body {
  background: rgb(255, 255, 255) !important;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  will-change: transform;
}
.text-blue {
  color: #003cac !important;
}
.ant-row.ant-form-item {
  margin-bottom: 0px !important;
}
a:active {
  color: #007bff !important;
}
a:hover {
  text-decoration: none !important;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-container {
  height: 100vh !important;
  padding: 20px !important;
}

.savings-select {
  width: 70% !important;
}

.savings-submit-mobile {
  width: 60px !important;
}

.card-header {
  background: transparent !important;
  border-bottom: 1px solid rgba(199, 199, 199, 0.125);
}

.card-header .title {
  font-size: 18px;
  font-weight: 700;
  color: rgb(0, 21, 41) !important;
}

.ant-layout-sider-trigger {
  display: none;
}
.ant-layout-header {
  padding: 0 !important;
}
.ant-menu-submenu-title {
  padding: 0 10px !important;
}
.ant-modal {
  padding: 20px !important;
}

.desktop-ant-badge {
  position: absolute !important;
  top: 10px !important;
  left: 5px !important;
}

.ant-drawer-body {
  padding: 13px !important;
}

.ant-row .ant-form-item {
  margin-bottom: 5px !important;
}

.ant-form-item-label {
  margin-top: 10px !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}
.ant-menu-item {
  padding-left: 10px !important;
  margin-bottom: 0 !important;
}

.subtext {
  color: rgb(53, 50, 50) !important;
}

.ant-input {
  height: 42px !important;
}

.lead-home {
  line-height: 33px;
  font-weight: 300;
  font-size: 1.6em;
}

.logo {
  background: #fff url("../src/Assets/Img/payhive-logo.png");
  height: 65px;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.ant-layout-header {
  background: #fff !important;
}

.bg-primaryo {
  background: rgb(0, 21, 41) !important;
}

.btn-primaryo {
  background: rgb(0, 21, 41) !important;
  color: #fff !important;
}

.display-5 {
  font-size: 2.5em;
  font-weight: bolder;
}

.card-rounded-top-left {
  border-radius: 2px 10px 2px 2px !important;
}

.card-transparent {
  background: transparent !important;
}

h2 {
  font: Arial, Helvetica, sans-serif;
  margin-top: 10px;
  top: 100px;
}
h3 {
  font: Arial, Helvetica, sans-serif;
  margin-top: 10px;
  top: 100px;
  color: blue;
}
ul {
  list-style-type: none;
}
.template {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
}
.render-template {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.empty_quotes {
  color: red;
  margin-top: 10px;
  top: 100px;
}
.ignore {
  overflow: auto;
  width: 100%;
  height: 300px;
  font-family: "Courier New", Courier, monospace;
  border: 1px solid #ccc;
}
.button-top {
  padding: 30px;
  display: block;
}
.initdata {
  padding-top: 10px;
  padding-right: 5px;
}

#render {
  background: #eee;
}
.display-color {
  color: gray;
}
.sort-btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #fff;
  cursor: pointer;
}

.btn-active {
  border: none;
  outline: none;
  padding: 3px 12px;
  background-color: #dfe7f5;
  color: #0047cc;
  font-style: italic;
}

.ant-carousel .slick-dots li {
  margin-top: 1.2rem !important;
}

.slick-dots li button:before {
  content: none;
}

.ant-carousel .slick-dots li button {
  background-color: gray !important;
  width: 20px !important;
  border-radius: 5px !important;
  height: 4px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #0047cc !important;
  width: 100% !important;
}

.ant-btn:disabled {
  background: #a4a2a2 !important;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #5fd32b;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 180px;
  height: 35px;
  margin-top: 20px;
}

.payment-btn {
  width: 130px !important;
  height: 30px !important;
}

@media (max-width: 480px) {
  .ajo-desktop-display {
    display: none !important;
  }
  .ajo-profile-container {
    margin-top: 7rem !important;
  }
  .ajo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Toastify__toast-container {
    width: 70vw !important;
    left: initial !important;
    right: 0 !important;
    margin: 10px !important;
  }
}

@media (min-width: 500px) {
  .add-vertical-hr {
    border-left: 1px solid #a4a2a2;
    width: 2px;
    padding: 5px;
    height: 40px;
  }
  .ajo-hamburger {
    display: none !important;
  }
  .ajo-mobile-display {
    display: none !important;
  }
  .ajo-profile-background {
    height: 400px !important;
  }
  .ajo-profile {
    left: 20% !important;
    width: 60% !important;
    margin: 0 auto !important;
    top: 140px !important;
  }
  .ajo-profile-container {
    margin-top: 2rem !important;
  }
  .ajo-invite-desktop {
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 #ccc;
    padding: 20px;
    margin: 0 auto;
    width: 80%;
  }
  .ajo-top-drawer {
    margin: 0 auto !important;
    width: 40% !important;
  }
  .ajo-header {
    justify-content: flex-end;
  }
}

.shadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-card {
  box-shadow: 0 1px 3px 0 rgba(213, 235, 243, 0.925), 0 1px 2px 0 rgba(198, 237, 250, 0.925);
}

.shadow-card:hover {
  box-shadow: 0 10px 15px -3px rgba(171, 217, 233, 0.925), 0 4px 6px -2px rgba(198, 237, 250, 0.925);
}

.ajo-form-input {
  height: 50px !important;
  border-radius: 5px;
}

.savingsHover {
  border-radius: 5px;
  height: 40px;
  padding: 10px;
}

.savingsHover:hover {
  background: #62abf02f;
}

li.nav-item .nav-link {
  min-width: 100px;
}

li.nav-item .nav-link {
  font-weight: 700;
}

/* index page */
.mastHeader {
  /* background: #f2f2f2; */
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  display: inline-block;
  min-height: 600px;
  width: 100%;
  position: relative;
}

.homeBlue {
  background: #f1f8fe url("./Assets/Img/line.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 0px 0 50px 0 !important;
  min-height: 500px;
}

.homeBlue .ant-card {
  border-radius: 20px;
  border: 1px solid #d3ebff;
  margin: 10px;
  padding: 20px 0;
  min-height: 250px;
}

.homeBlue .ant-card p {
  min-height: 72px;
}

.homeBlue .ant-card h2 {
  margin-bottom: 15px;
}

.ant-btn-primary {
  background: #0048cb !important;
  border: 1px solid #0048cb;
}

.ant-btn-primary:hover {
  background: #033da8 !important;
}

.ourPromise h2 {
  font-size: 1.8em;
  font-weight: bold;
}

.ourPromise h3 {
  font-size: 1.1em;
  font-weight: bold;
}

.mastTitle {
  background: rgba(255, 255, 255, 0.959);
  box-shadow: 0 20px 25px -5px rgba(213, 235, 250, 0.8), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  min-height: 300px;
  padding: 30px !important;
  border-radius: 20px;
  margin-left: 5%;
  position: absolute !important;
  bottom: 80px;
}

.BrainhubCarouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 550px;
}

.mastImage {
  width: 90%;
  border-radius: 0 85px 0 85px;
}

@media only screen and (max-width: 600px) {
  .mastImage {
    width: 100%;
    border-radius: 10px;
    margin-top: -150%;
  }

  .BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 600px !important;
  }

  .mastTitle {
    border: 1px solid #f2f2f2;
    background: rgba(255, 255, 255, 0.959);
    box-shadow: 0 20px 25px -5px rgba(213, 235, 250, 0.8), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    min-height: 350px;
    padding: 30px !important;
    border-radius: 20px;
    margin-left: 5%;
    /* position: absolute !important; */
    top: 2px;
    margin-top: -40%;
  }
}

.mastTitle h1 {
  font-size: 1.8em;
  font-weight: bold;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
  padding: 0px 2em;
}

.site-layout-background {
  padding: 24px;
}

@media only screen and (max-width: 600px) {
  .noPadding {
    padding: 0px !important;
  }

  .noMargin {
    margin-left: 0px !important;
  }

  .ant-layout-content {
    flex: auto;
    min-height: 0;
    padding: 0px !important;
  }

  .site-layout-background {
    padding: 0px !important;
  }
}

.NoAuthHeader .ant-menu-light .ant-menu-item:hover {
  background: transparent !important;
}

.corner {
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.NoAuthHeader {
  background: rgba(255, 255, 255, 0.137);
  /* margin-bottom: 20px; */
}

.sliderImg {
  border-radius: 10px;
  width: 100%;
}

button.ant-btn.ant-btn-round.ant-btn-lg {
  height: 50px;
  font-weight: bold;
  border-radius: 10px !important;
}

.loginBtn {
  background: #fff !important;
  margin-right: 30px;
  border: none !important;
}

footer {
  background: #f1f8fe;
  color: #333;
  padding: 50px 0;
}

footer .ant-col,
footer p {
  color: var(--text-dark) !important;
}

footer h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

footer ul {
  list-style: none;
  padding: 0;
}
footer ul li {
  font-size: 12px;
  color: var(--text-dark);
  padding: 5px 0;
}

footer ul li:hover {
  padding-left: 5px;
  transition: 1s;
  font-weight: 500;
}

footer .copyright {
  margin-top: 30px;
  border-top: 1px solid #c4c4c4;
  padding-top: 15px;
}

.copyright .anticon svg {
  font-size: 25px !important;
  color: #0048cb;
  margin: 0 5px;
}

.copyright .anticon svg:hover {
  font-size: 25px !important;
  color: #043a9e;
}

.navbar-toggler-icon {
  background: url("../src/Assets/Img/hamburger.png") !important;
  background-size: contain !important;
}
